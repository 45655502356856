// 中文语言包：\src\i18n\lang\zh-cn.ts
export default {
  welcome: {
    name: "欢迎",
  },
  title: {
    name: "全球游戏销售管理",
  },
  menu: {
    1000: "图片管理",
    1001: "产品管理",
    1002: "库存管理",
    1003: "价格管理",
    1004: "雷蛇购买",
    1005: "雷蛇充值",
    1006: "数据对账",
    1007: "识图任务",
    1008: "识图结果",
    1009: "产品列表",
    1010: "产品推荐",
    1011: "库存列表",
    1012: "库存明细",
    1013: "价格列表",
    1014: "改价记录",
    1015: "账户管理",
    1016: "购买任务",
    1017: "购买记录",
    1018: "充值商",
    1019: "钱包明细",
    1020: "充值任务",
    1021: "充值账户",
    1022: "充值码",
    1023: "对账统计",
    1024: "系统工具",
    1025: "汇率转换",
    1026: "配置管理",
    1027: "采购管理",
    1028: "供应商管理",
    1029: "关联关系管理",
    1030: "内部货物管理",
    1031: "采购日志",
    1032: "采购订单",
    1033: "记账栏目",
    1034: "记账流水",
    1035: "账单快照",
    1036: "账单报表",
  },
  platformType: {
    1: "雷蛇平台",
    2: "批发平台",
    3: "c2c平台",
    4: "大后台",
  },
  userInfo: {
    myWorkOrder: {
      feedback: "我的反馈",
      creatOrder: "创建工单",
      accountIssue: "账号问题",
      digitalProductIssue: "数字产品问题",
      SelfOperatedProductIssue: "自运营产品问题",
      C2CRechargeIssue: "c2c充值问题",
      OtherIssues: "其他问题",
      Unread: "未读",
      Processing: "处理中",
      shelve: "搁置",
      Completed: "处理完成",
      rejectHandle: "拒绝处理",
      Filter: "筛选器",
      TicketType: "工单类型",
      Select: "请选择",
      TicketStatus: "工单状态",
      CreateTime: "创建时间",
      ContactCustomerService: "联系客服",
      CustomerService: "客服",
      AverageResponseTime: "一般回复时长",
      WorkingDays: "个工作日",
      EndConversation: "结束会话",
      workOrder: "工单",
      ProductName: "产品名称",
      Order: "订单",
      OrderNumber: "订单号",
      EmailAccount: "邮箱账号",
      ProblemDescription: "问题描述",
      placeholder: "请尽可能详细描述您所遇到的问题",
      description: "提供附件可以更好的诠释您的问题（最多可上传5个文件）",
      PleaseInput: "请输入",
      upload: "上传文件",
      startTime: "开始时间",
      endTime: "结束时间",
      customName:"客户名称"
    },
  },
};
