// 英文语言包：\src\i18n\lang\en.ts
export default {
  welcome: {
    name: "welcome",
  },
  title: {
    name: "FutureSpacegamer Sale Manager",
  },
  menu: {
    1000: "Picture Manage",
    1001: "Product Manage",
    1002: "Stock Manage",
    1003: "Price Manage",
    1004: "Razer Buy",
    1005: "Razer Recharge",
    1006: "Billing Statistics",
    1007: "Identify Images Task",
    1008: "Identify Images Result",
    1009: "Product List",
    1010: "Product Recommendations",
    1011: "Stock List",
    1012: "Stock Detailed",
    1013: "Price List",
    1014: "Change Price Records",
    1015: "Account manage",
    1016: "Purchase Task",
    1017: "Purchase Records",
    1018: "Recharger",
    1019: "Wallet Details",
    1020: "Recharge Task",
    1021: "Recharge Account",
    1022: "Recharge Code",
    1023: "Reconciliation Statistics",
    1024: "System Tool",
    1025: "Exchange Rate",
    1026: "Configuration Manage",
    1027: "Purchase Manage",
    1028: "Supplier Manage",
    1029: "Association Manage",
    1030: "Inside Goods Manage",
    1031: "Purchase Logs",
    1032: "Purchase Orders",
    1033: "Bill Entry",
    1034: "Bill Flow",
    1035: "Bill Snapshot",
    1036: "Bill Report",
  },
  platformType: {
    1: "雷蛇平台",
    2: "批发平台",
    3: "c2c平台",
    4: "大后台",
  },
  userInfo: {
    myWorkOrder: {
      feedback: "My Feedback",
      creatOrder: "Create",
      accountIssue: "Account Issue",
      digitalProductIssue: "Digital Product Issue",
      SelfOperatedProductIssue: "Self-Operated Product Issue",
      C2CRechargeIssue: "C2C Recharge Issue",
      OtherIssues: "Other Issues",
      Unread: "Unread",
      Processing: "Processing",
      shelve: "shelve",
      Completed: "Completed",
      rejectHandle: "reject Handle",
      Filter: "Filter",
      TicketType: "Ticket Type",
      Select: "Select",
      TicketStatus: "Ticket Status",
      CreateTime: "Create Time",
      ContactCustomerService: "Contact Customer Service",
      CustomerService: "Customer Service",
      AverageResponseTime: "Average Response Time",
      WorkingDays: "Working Days",
      EndConversation: "End Conversation",
      workOrder: "Work Order",
      ProductName: "Product Name",
      Order: "Order",
      OrderNumber: "Order Number",
      EmailAccount: "Email Account",
      ProblemDescription: "Problem Description",
      placeholder: "Please provide a detailed description of the issue you encountered",
      description: "Attaching files can better illustrate your problem (maximum 5 files can be uploaded)",
      PleaseInput: "Please Input",
      upload: "Upload File",
      startTime: "start time",
      endTime: "end time",
      customName:"Customer Name"

    },
  },
};
