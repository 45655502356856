import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import piniaPersistedState from "pinia-plugin-persistedstate";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@element-plus/icons-vue";
import "element-plus/theme-chalk/dark/css-vars.css";
import "element-plus/theme-chalk/display.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";

import { GoogleTranslateSelect } from "@google-translate-select/vue3";

//国际化
// i18n
import i18n from "./i18n";

//事件总线
import mitt from "mitt";

//富文本
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
const emitter = mitt();

library.add(fas, faTwitter, faBell);

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersistedState);
app.use(pinia);
app.use(i18n);
// app.component("Editor", Editor);
// app.component("Toolbar", Toolbar);
app.use(GoogleTranslateSelect);
app.provide("emitter", emitter);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router).use(ElementPlus).mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
