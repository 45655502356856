import axios from "axios";
import zhCn from "@/i18n/lang/zh-cn";
import en from "@/i18n/lang/en";
import type { RouteLocationNormalized } from "vue-router";
import router from "@/router";
import { ElMessage } from "element-plus";
import {useLocalStore} from "@/store";

// 版本检查
export const versionCheck = async (routerTo: RouteLocationNormalized) => {
  const localStore = useLocalStore();
  if (!location.href.includes("//localhost:")) {
    const requestUrl = `${import.meta.env.VITE_BASE_JSON_URL}/version.json?t=${new Date().getTime()}`;
    const response = await axios.get(requestUrl);
    // 清除缓存
    caches?.keys()?.then((a) => {return Promise.all(a?.map((b) => caches.delete(b)))});
    //process.env.VITE__APP_VERSION__  获取环境变量设置的值，判断是否与生成的版本信息一致
    if (response?.data?.version && process.env.VITE__APP_VERSION__ !== response?.data?.version) {
      // 最多循环三次更新
      if (localStore.versionUpdateLoop < 3) {
        if(routerTo?.path) {
          await router.push(routerTo?.path);
        }
        ElMessage.warning("服务更新啦");
        localStore.versionUpdateLoop++;
        let tempUrl = window.location.href;
        tempUrl = `${tempUrl}${tempUrl.includes("?") ? "&" : "?"}t=${Date.now()}`;
        window.location.replace(tempUrl);
      }
    } else {
      localStore.versionUpdateLoop = 0;
    }
  }
};
