<template>
  <transition name="slide">
    <el-config-provider :locale="locale">
      <suspense>
        <router-view />
      </suspense>
    </el-config-provider>
  </transition>
  <el-dialog v-model="versionVisible" title="嗨，我们的服务更新啦" width="30%" center>
    <span>
      <h3>注意：检测到已发布新的版本！</h3>
      <h4>点击确认按钮或者手动刷新浏览器，将会自动刷新页面更新版本，您当前页面已填写的内容将会清除，请谨慎选择操作！</h4>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click.stop="versionVisible = false">取消</el-button>
        <el-button type="primary" @click.stop="confirmUpdate"> 确认 </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { useStore, useLocalStore } from "@/store";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { computed, onUnmounted, ref } from "vue";

const store = useStore();
const localStore = useLocalStore();

//EL 国际化配置 暂时支持中英文
const language = computed(() => store.language);
const lang: any = {
  zhCn,
  en,
};
const locale = computed(() => {
  return lang[language.value];
});

//resize 报错解决
const debounce = (fn: any, delay: any) => {
  let timer: any = null;
  return function () {
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback: any) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

//版本更新检测
const versionVisible = ref(false);
const compareVersions = (v1: number, v2: number) => {
  if (v1 > v2) {
    return true;
  } else {
    return false;
  }
};

const confirmUpdate = () => {
  location.reload();
  versionVisible.value = false;
  location.reload();
};

/**版本更新定时查询*/
let timer: any = null;
const packageJson = ref();
/**版本更新定时查询*/
// 在组件卸载时清除定时器
onUnmounted(() => {
  clearInterval(timer);
});
// onMounted(() => {
//   autofit.init(
//     {
//       dh: 1080,
//       dw: 1920,
//       el: "#app",
//       resize: true,
//     },
//     false
//   ); // 可关闭控制台运行提示输出
// });
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
